export const ENVIROMENT_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STAGE === "prod"
      ? "https://www.zermatusa.com/"
      : process.env.REACT_APP_STAGE === "qa"
      ? "http://www.zermatusa.biz/"
      : "http://10.10.1.16:8082/"
    : process.env.REACT_APP_STAGE === "dev"
    ? "http://10.10.1.16:8082/"
    : process.env.REACT_APP_STAGE === "qa"
    ? "http://www.zermatusa.biz/"
    : "http://www.zermatusa.biz/";
//CATCHING ERRORS FETCHING
export const DATA_FETCH_FAILED = "DATA_FETCH_FAILED";
//FACEBOOK
export const REACT_APP_FACEBOOK_ENVIROMENT_URL = "https://graph.facebook.com/";
export const REACT_APP_FACEBOOK_VERSION_API = "v14.0/";
export const REACT_APP_FACEBOOK_ACCESS_TOKEN =
  "EAAEeZB5cNr0kBANXT2ZCECe2hw2z0fecSkRTSGnVbD4z25IPlbMagaydCZAa9OF3PwrBpZAFNZAJFBExZB40aO0SbEb0S8QtPDfnpsnZBeS8VoWb7dlmSHgcMg8HFygrgI3GRlSfrH4EZB6ORf1ZAZBsGPwZBPSc7PRN70rDKrN7oJWHyPskIXz8sZCi";

//TWILIO
export const REQUEST_TWILIO_CELLPHONE_VALIDATION =
  "REQUEST_TWILIO_CELLPHONE_VALIDATION";
export const RESPONSE_TWILIO_CELLPHONE_VALIDATION =
  "RESPONSE_TWILIO_CELLPHONE_VALIDATION";
export const REQUEST_TWILIO_CELLPHONE_CODE_VALIDATION =
  "REQUEST_TWILIO_CELLPHONE_CODE_VALIDATION";
export const RESPONSE_TWILIO_CELLPHONE_CODE_VALIDATION =
  "RESPONSE_TWILIO_CELLPHONE_CODE_VALIDATION";
export const CLEAN_REDUCER_CELLPHONE_VALIDATION =
  "CLEAN_REDUCER_CELLPHONE_VALIDATION";

//EMAIL
export const REQUEST_EMAIL_VALIDATION = "REQUEST_EMAIL_VALIDATION";
export const RESPONSE_EMAIL_VALIDATION = "RESPONSE_EMAIL_VALIDATION";
export const CLEAN_REDUCER_EMAIL_VALIDATION = "CLEAN_REDUCER_EMAIL_VALIDATION";

//ADDRESS
export const REQUEST_CLIENT_ADDRESS_VALIDATION =
  "REQUEST_CLIENT_ADDRESS_VALIDATION";
export const RESPONSE_CLIENT_ADDRESS_VALIDATION =
  "RESPONSE_CLIENT_ADDRESS_VALIDATION";
export const CLEAN_REDUCER_CLIENT_ADDRESS_VALIDATION =
  "CLEAN_REDUCER_CLIENT_ADDRESS_VALIDATION";
export const CLEAN_REGISTER_FROM_CHEKOUT_FISCAL_ADDRESS_REDUCER =
  "CLEAN_REGISTER_FROM_CHEKOUT_FISCAL_ADDRESS_REDUCER";

//USER DATA VALIDATION
export const REQUEST_USER_DATA_VALIDATION = "REQUEST_USER_DATA_VALIDATION";
export const RESPONSE_USER_DATA_VALIDATION = "RESPONSE_USER_DATA_VALIDATION";
export const CLEAN_USER_DATA_VALIDATION = "CLEAN_USER_DATA_VALIDATION";

//GENERAL
export const REQUEST_FIRST_CHARGE_SITE = "REQUEST_FIRST_CHARGE_SITE";
export const RESPONSE_FIRST_CHARGE_SITE = "RESPONSE_FIRST_CHARGE_SITE";
export const RESPONSE_FIRST_CHARGE_SITE_RELOAD =
  "RESPONSE_FIRST_CHARGE_SITE_RELOAD";
export const REQUEST_FIRST_CHARGE_SITE_RELOAD =
  "REQUEST_FIRST_CHARGE_SITE_RELOAD";
export const REQUEST_STATES_CITIES = "REQUEST_STATES_CITIES";
export const RESPONSE_STATES_CITIES = "RESPONSE_STATES_CITIES";

//FACEBOOK
export const REQUEST_INSTAGRAM_POSTS = "REQUEST_INSTAGRAM_POSTS";
export const RESPONSE_INSTAGRAM_POSTS = "RESPONSE_INSTAGRAM_POSTS";

//CLIENT
export const REQUEST_NEWSLETTER_SUSCRIBE_EVALUATION =
  "REQUEST_NEWSLETTER_SUSCRIBE_EVALUATION";
export const RESPONSE_NEWSLETTER_SUSCRIBE_EVALUATION =
  "RESPONSE_NEWSLETTER_SUSCRIBE_EVALUATION";
export const CLEAN_NEWSLETTER_SUSCRIBE_VALIDATION =
  "CLEAN_NEWSLETTER_SUSCRIBE_VALIDATION";
export const REQUEST_CLIENT_LOGIN = "REQUEST_CLIENT_LOGIN";
export const RESPONSE_CLIENT_LOGIN = "RESPONSE_CLIENT_LOGIN";
export const REQUEST_REGISTER_NEW_CLIENT = "REQUEST_REGISTER_NEW_CLIENT";
export const RESPONSE_REGISTER_NEW_CLIENT = "RESPONSE_REGISTER_NEW_CLIENT";
export const REQUEST_CLIENT_INFORMATION = "REQUEST_CLIENT_INFORMATION";
export const RESPONSE_CLIENT_INFORMATION = "RESPONSE_CLIENT_INFORMATION";
export const REQUEST_CLIENT_UPLOAD_IMAGE_PROFILE =
  "REQUEST_CLIENT_UPLOAD_IMAGE_PROFILE";
export const RESPONSE_CLIENT_UPLOAD_IMAGE_PROFILE =
  "RESPONSE_CLIENT_UPLOAD_IMAGE_PROFILE";
export const CLEAN_CLIENT_UPLOAD_IMAGE_REDUCER =
  "CLEAN_CLIENT_UPLOAD_IMAGE_REDUCER";
export const REQUEST_UPDATE_CLIENT_INFORMATION =
  "REQUEST_UPDATE_CLIENT_INFORMATION";
export const RESPONSE_UPDATE_CLIENT_INFORMATION =
  "RESPONSE_UPDATE_CLIENT_INFORMATION";
export const REQUEST_WEBPAGE_CONTACT_REGISTER =
  "REQUEST_WEBPAGE_CONTACT_REGISTER";
export const RESPONSE_WEBPAGE_CONTACT_REGISTER =
  "RESPONSE_WEBPAGE_CONTACT_REGISTER";
export const REQUEST_EMAIL_FROM_RETARGETING_PROGRAM =
  "REQUEST_EMAIL_FROM_RETARGETING_PROGRAM";
export const RESPONSE_EMAIL_FROM_RETARGETING_PROGRAM =
  "RESPONSE_EMAIL_FROM_RETARGETING_PROGRAM";
export const CLEAN_REDUCER_WEBPAGE_CONTACT_REGISTER =
  "CLEAN_REDUCER_WEBPAGE_CONTACT_REGISTER";
export const CLEAN_REDUCER_CLIENT_REGISTER = "CLEAN_REDUCER_CLIENT_REGISTER";
export const CLEAN_REDUCER_CLIENT_UPDATE_INFORMATION =
  "CLEAN_REDUCER_CLIENT_UPDATE_INFORMATION";
export const REQUEST_CLIENT_ADMINISTRATION_MENU =
  "REQUEST_CLIENT_ADMINISTRATION_MENU";
export const RESPONSE_CLIENT_ADMINISTRATION_MENU =
  "RESPONSE_CLIENT_ADMINISTRATION_MENU";
export const REQUEST_CLIENT_LOGOUT = "REQUEST_CLIENT_LOGOUT";
export const REQUEST_PASSWORD_RECOVERY = "REQUEST_PASSWORD_RECOVERY";
export const RESPONSE_PASSWORD_RECOVERY = "RESPONSE_PASSWORD_RECOVERY";
export const REQUEST_VALIDATE_PASSWORD_RECOVERY_CODE =
  "REQUEST_VALIDATE_PASSWORD_RECOVERY_CODE";
export const RESPONSE_VALIDATE_PASSWORD_RECOVERY_CODE =
  "RESPONSE_VALIDATE_PASSWORD_RECOVERY_CODE";
export const REQUEST_RESET_USER_PASSWORD = "REQUEST_RESET_USER_PASSWORD";
export const RESPONSE_RESET_USER_PASSWORD = "RESPONSE_RESET_USER_PASSWORD";
export const CLEAN_REDUCER_RECOVERY_CLIENT_PASSWORD =
  "CLEAN_REDUCER_RECOVERY_CLIENT_PASSWORD";
export const REQUEST_GET_CLIENT_CONFIRMED_ORDERS =
  "REQUEST_GET_CLIENT_CONFIRMED_ORDERS";
export const RESPONSE_GET_CLIENT_CONFIRMED_ORDERS =
  "RESPONSE_GET_CLIENT_CONFIRMED_ORDERS";
export const REQUEST_VALIDATE_INITIAL_BANNER =
  "REQUEST_VALIDATE_INITIAL_BANNER";
export const RESPONSE_VALIDATE_INITIAL_BANNER =
  "RESPONSE_VALIDATE_INITIAL_BANNER";
export const REQUEST_VALIDATE_IDTYPE = "REQUEST_VALIDATE_IDTYPE";
export const RESPONSE_VALIDATE_IDTYPE = "RESPONSE_VALIDATE_IDTYPE";
export const CLEAN_VALIDATE_IDTYPE_REDUCER = "CLEAN_VALIDATE_IDTYPE_REDUCER";
export const RESPONSE_UPDATE_DATA_FROM_CHECKOUT =
  "RESPONSE_UPDATE_DATA_FROM_CHECKOUT";
export const REQUEST_UPDATE_DATA_FROM_CHECKOUT =
  "REQUEST_UPDATE_DATA_FROM_CHECKOUT";
export const RESPONSE_INSERT_FISCAL_ADDRESS = "RESPONSE_INSERT_FISCAL_ADDRESS";
export const REQUEST_INSERT_FISCAL_ADDRESS = "REQUEST_INSERT_FISCAL_ADDRESS";
export const CLEAN_USER_INFORMATION_REDUCER = "CLEAN_USER_INFORMATION_REDUCER";
export const CLEAN_CLIENT_LOGIN_REDUCER = "CLEAN_CLIENT_LOGIN_REDUCER";
export const CLEAN_REGISTER_FROM_CHEKOUT_REDUCER =
  "CLEAN_REGISTER_FROM_CHEKOUT_REDUCER";
export const REQUEST_VALIDATE_OWNER_EMAIL = "REQUEST_VALIDATE_OWNER_EMAIL";
export const RESPONSE_VALIDATE_OWNER_EMAIL = "RESPONSE_VALIDATE_OWNER_EMAIL";
export const REQUEST_VALIDATE_OWNER_PHONE = "REQUEST_VALIDATE_OWNER_PHONE";
export const RESPONSE_VALIDATE_OWNER_PHONE = "RESPONSE_VALIDATE_OWNER_PHONE";
export const CLEAN_TWILIO_CELLVALIDATION_REDUCER =
  "CLEAN_TWILIO_CELLVALIDATION_REDUCER";
export const CLEAN_TWILIO_CELL_CODEVALIDATION_REDUCER =
  "CLEAN_TWILIO_CELL_CODEVALIDATION_REDUCER";

export const REQUEST_GET_DATA_SPONSOR_BY_ID = "REQUEST_GET_DATA_SPONSOR_BY_ID";
export const RESPONSE_GET_DATA_SPONSOR_BY_ID =
  "RESPONSE_GET_DATA_SPONSOR_BY_ID";
export const REQUEST_GET_DATA_SPONSOR_BY_NAME =
  "REQUEST_GET_DATA_SPONSOR_BY_NAME";
export const RESPONSE_GET_DATA_SPONSOR_BY_NAME =
  "RESPONSE_GET_DATA_SPONSOR_BY_NAME";
export const REQUEST_GET_DATA_SPONSOR_BY_CITY =
  "REQUEST_GET_DATA_SPONSOR_BY_CITY";
export const RESPONSE_GET_DATA_SPONSOR_BY_CITY =
  "RESPONSE_GET_DATA_SPONSOR_BY_CITY";
export const REQUEST_ASSIGN_SPONSOR = "REQUEST_ASSIGN_SPONSOR";
export const RESPONSE_ASSIGN_SPONSOR = "RESPONSE_ASSIGN_SPONSOR";
export const REQUEST_UPDATE_SHIPPING_ADDRESS =
  "REQUEST_UPDATE_SHIPPING_ADDRESS";
export const RESPONSE_UPDATE_SHIPPING_ADDRESS =
  "RESPONSE_UPDATE_SHIPPING_ADDRESS";
export const CLEAN_UPDATE_SHIPPING_ADDRESS = "CLEAN_UPDATE_SHIPPING_ADDRESS";
export const CLEAN_GET_DATA_SPONSOR_LIST = "CLEAN_GET_DATA_SPONSOR_LIST";
export const REQUEST_CLIENT_REGISTER_QUESTION_SUPPORT =
  "REQUEST_CLIENT_REGISTER_QUESTION_SUPPORT";
export const RESPONSE_CLIENT_REGISTER_QUESTION_SUPPORT =
  "RESPONSE_CLIENT_REGISTER_QUESTION_SUPPORT";
export const CLEAN_CLIENT_SUPPORT_QUESTION_REDUCER =
  "CLEAN_CLIENT_SUPPORT_QUESTION_REDUCER";
export const REQUEST_GET_DATA_SPONSOR_AUTOMATIC =
  "REQUEST_GET_DATA_SPONSOR_AUTOMATIC";
export const RESPONSE_GET_DATA_SPONSOR_AUTOMATIC =
  "RESPONSE_GET_DATA_SPONSOR_AUTOMATIC";
export const REQUEST_ASSIGN_SPONSOR_AUTOMATIC =
  "REQUEST_ASSIGN_SPONSOR_AUTOMATIC";
export const RESPONSE_ASSIGN_SPONSOR_AUTOMATIC =
  "RESPONSE_ASSIGN_SPONSOR_AUTOMATIC";
export const CLEAN_GET_DATA_SPONSOR_AUTOMATIC =
  "CLEAN_GET_DATA_SPONSOR_AUTOMATIC";
export const REQUEST_CLIENT_IMMEDIATE_GAINS = "REQUEST_IMMEDIATE_GAINS";
export const RESPONSE_CLIENT_IMMEDIATE_GAINS = "RESPONSE_IMMEDIATE_GAINS";
export const CLEAN_CLIENT_IMMEDIATE_GAINS_REDUCER =
  "CLEAN_IMMEDIATE_GAINS_REDUCER";
export const CLEAN_CLIENT_RETARGETING_PROGRAM =
  "CLEAN_CLIENT_RETARGETING_PROGRAM";
export const REQUEST_LOGG_USER_ACTIVITY = "REQUEST_LOGG_USER_ACTIVITY";
export const RESPONSE_LOGG_USER_ACTIVITY = "RESPONSE_LOGG_USER_ACTIVITY";
export const REQUEST_CLIENT_GUEST_INFORMATION_EMAIL =
  "REQUEST_CLIENT_GUEST_INFORMATION_EMAIL";
export const RESPONSE_CLIENT_GUEST_INFORMATION_EMAIL =
  "RESPONSE_CLIENT_GUEST_INFORMATION_EMAIL";
export const CLEAN_CLIENT_GUEST_INFORMATION_EMAIL =
  "CLEAN_CLIENT_GUEST_INFORMATION_EMAIL";
export const CLEAN_ASSGIN_SPONSOR_REDUCER = "CLEAN_ASSGIN_SPONSOR_REDUCER";
export const CLEAN_REDUCER_PASSWORD_RECOVERY =
  "CLEAN_REDUCER_PASSWORD_RECOVERY";
////////////
export const REQUEST_REGISTRATION_LOGIN_DATA =
  "REQUEST_REGISTRATION_LOGIN_DATA";
export const RESPONSE_REGISTRATION_LOGIN_DATA =
  "RESPONSE_REGISTRATION_LOGIN_DATA";
export const REQUEST_NEW_REGISTRATION_CHECKOUT_DATA =
  "REQUEST_NEW_REGISTRATION_CHECKOUT_DATA";
export const RESPONSE_NEW_REGISTRATION_CHECKOUT_DATA =
  "RESPONSE_NEW_REGISTRATION_CHECKOUT_DATA";
export const REQUEST_UPDATE_ADDRESS_CHECKOUT_DATA =
  "REQUEST_UPDATE_ADDRESS_CHECKOUT_DATA";
export const RESPONSE_UPDATE_ADDRESS_CHECKOUT_DATA =
  "RESPONSE_UPDATE_ADDRESS_CHECKOUT_DATA";

///REFERRER
export const REQUEST_REFERRER_CELLPHONE_VALIDATION =
  "REQUEST_REFERRER_CELLPHONE_VALIDATION";
export const RESPONSE_REFERRER_CELLPHONE_VALIDATION =
  "RESPONSE_REFERRER_CELLPHONE_VALIDATION";
export const REQUEST_REFERRER_EMAIL_VALIDATION =
  "REQUEST_REFERRER_EMAIL_VALIDATION";
export const RESPONSE_REFERRER_EMAIL_VALIDATION =
  "RESPONSE_REFERRER_EMAIL_VALIDATION";
export const REQUEST_REFERRER_NEW_REGISTER = "REQUEST_REFERRER_NEW_REGISTER";
export const RESPONSE_REFERRER_NEW_REGISTER = "RESPONSE_REFERRER_NEW_REGISTER";
export const CLEAN_REFERRER_REDUCER = "CLEAN_REFERRER_REDUCER";
export const REQUEST_REFERRER_CODE = "REQUEST_REFERRER_CODE";
export const RESPONSE_REFERRER_CODE = "RESPONSE_REFERRER_CODE";
export const REQUEST_REFERRER_CODE_SENDED_LOG =
  "REQUEST_REFERRER_CODE_SENDED_LOG";
export const RESPONSE_REFERRER_CODE_SENDED_LOG =
  "RESPONSE_REFERRER_CODE_SENDED_LOG";
export const CLEAN_REFERRER_SENDED_CODE_LOG = "CLEAN_REFERRER_SENDED_CODE_LOG";
export const REQUEST_REFERRER_LIST = "REQUEST_REFERRER_LIST";
export const RESPONSE_REFERRER_LIST = "RESPONSE_REFERRER_LIST";

//HOME
export const REQUEST_HOME_MODULE_INFO = "REQUEST_HOME_MODULE_INFO";
export const RESPONSE_HOME_MODULE_INFO = "RESPONSE_HOME_MODULE_INFO";
export const REQUEST_ENTREPENOUR_HOME_MODULE_INFO =
  "REQUEST_ENTREPENOUR_HOME_MODULE_INFO";
export const RESPONSE_ENTREPENOUR_HOME_MODULE_INFO =
  "RESPONSE_ENTREPENOUR_HOME_MODULE_INFO";
export const REQUEST_CLIENT_PREFERENT_MODULE =
  "REQUEST_CLIENT_PREFERENT_MODULE";
export const RESPONSE_CLIENT_PREFERENT_MODULE =
  "RESPONSE_CLIENT_PREFERENT_MODULE";
export const REQUEST_ENTERPRENOUR_MODULE = "REQUEST_ENTERPRENOUR_MODULE";
export const RESPONSE_ENTERPRENOUR_MODULE = "RESPONSE_ENTERPRENOUR_MODULE";
export const RESPONSE_ZCARE_MODULE = "RESPONSE_ZCARE_MODULE";

//NAVBAR
export const REQUEST_NAVBAR_MODULE_INFO = "REQUEST_NAVBAR_MODULE_INFO";
export const RESPONSE_NAVBAR_MODULE_INFO = "RESPONSE_NAVBAR_MODULE_INFO";

//FOOTER
export const REQUEST_FOOTER_MODULE_INFO = "REQUEST_FOOTER_MODULE_INFO";
export const RESPONSE_FOOTER_MODULE_INFO = "RESPONSE_FOOTER_MODULE_INFO";

//PRODUCTS
export const REQUEST_TRPREX_PRODUCTS = "REQUEST_TRPREX_PRODUCTS";
export const RESPONSE_TRPREX_PRODUCTS = "RESPONSE_TRPREX_PRODUCTS";
export const REQUEST_FAVORITES = "REQUEST_FAVORITES";
export const RESPONSE_FAVORITES = "RESPONSE_FAVORITES";
export const REQUEST_ALL_PRODUCTS = "REQUEST_ALL_PRODUCTS";
export const RESPONSE_ALL_PRODUCTS = "RESPONSE_ALL_PRODUCTS";
export const RESPONSE_ALL_CATEGORIES = "RESPONSE_ALL_CATEGORIES";
export const REQUEST_SET_PRODUCT_ON_FAVORITES =
  "REQUEST_SET_PRODUCT_ON_FAVORITES";
export const RESPONSE_SET_PRODUCT_ON_FAVORITES =
  "RESPONSE_SET_PRODUCT_ON_FAVORITES";
export const CLEAN_SET_FAVORITES_REDUCER = "CLEAN_SET_FAVORITES_REDUCER";
export const REQUEST_GET_ZCARE_PRODUCT = "REQUEST_GET_ZCARE_PRODUCT";
export const RESPONSE_GET_ZCARE_PRODUCT = "RESPONSE_GET_ZCARE_PRODUCT";
export const REQUEST_GET_SETUP_KIT_COMPONENTS =
  "REQUEST_GET_SETUP_KIT_COMPONENTS";
export const RESPONSE_GET_SETUP_KIT_COMPONENTS =
  "RESPONSE_GET_SETUP_KIT_COMPONENTS";
export const REQUEST_GET_ADMISSION_KITS = "REQUEST_GET_ADMISSION_KITS";
export const RESPONSE_GET_ADMISSION_KITS = "RESPONSE_GET_ADMISSION_KITS";
export const REQUEST_ALL_PRODUCTS_BY_LANG = "REQUEST_ALL_PRODUCTS_BY_LANG";
export const RESPONSE_ALL_PRODUCTS_BY_LANG = "RESPONSE_ALL_PRODUCTS_BY_LANG";
export const REQUEST_ADD_SET_CATEGORIES_REDUCER =
  "REQUEST_ADD_SET_CATEGORIES_REDUCER";
export const RESPONSE_ADD_SET_CATEGORIES_REDUCER =
  "RESPONSE_ADD_SET_CATEGORIES_REDUCER";
export const REQUEST_REMOVE_SET_CATEGORIES_REDUCER =
  "REQUEST_REMOVE_SET_CATEGORIES_REDUCER";
export const RESPONSE_REMOVE_SET_CATEGORIES_REDUCER =
  "RESPONSE_REMOVE_SET_CATEGORIES_REDUCER";
export const REQUEST_ALL_PRODUCTS_ASYNC = "REQUEST_ALL_PRODUCTS_ASYNC";
export const RESPONSE_ALL_PRODUCTS_ASYNC = "RESPONSE_ALL_PRODUCTS_ASYNC";
export const REQUEST_ALL_CAREGORIES_ASYNC = "REQUEST_ALL_CAREGORIES_ASYNC";
export const RESPONSE_ALL_CAREGORIES_ASYNC = "RESPONSE_ALL_CAREGORIES_ASYNC";
export const REQUEST_GET_BEAUTY_BOXES = "REQUEST_GET_BEAUTY_BOXES";
export const RESPONSE_GET_BEAUTY_BOXES = "RESPONSE_GET_BEAUTY_BOXES";
export const REQUEST_ALL_PRODUCT_FAMILIES_ASYNC =
  "REQUEST_ALL_PRODUCT_FAMILIES_ASYNC";
export const RESPONSE_ALL_PRODUCT_FAMILIES_ASYNC =
  "RESPONSE_ALL_PRODUCT_FAMILIES_ASYNC";
export const CLEAN_GET_SETUP_KIT_COMPONENTS = "CLEAN_GET_SETUP_KIT_COMPONENTS";

//CART
export const REQUEST_CART_CURRENT_ORDER = "REQUEST_CART_CURRENT_ORDER";
export const RESPONSE_CART_CURRENT_ORDER = "RESPONSE_CART_CURRENT_ORDER";
export const REQUEST_CART_TAX_ORDER = "REQUEST_CART_TAX_ORDER";
export const RESPONSE_CART_TAX_ORDER = "RESPONSE_CART_TAX_ORDER";
export const REQUEST_CART_INVENTORY_ASSYGN = "REQUEST_CART_INVENTORY_ASSYGN";
export const RESPONSE_CART_INVENTORY_ASSYGN = "RESPONSE_CART_INVENTORY_ASSYGN";
export const RESPONSE_CART_GET_ORDER = "RESPONSE_CART_GET_ORDER";
export const REQUEST_CART_GET_ORDER = "REQUEST_CART_GET_ORDER";
export const RESPONSE_CART_COUPON_LIST = "RESPONSE_CART_COUPON_LIST";
export const REQUEST_CART_COUPON_LIST = "REQUEST_CART_COUPON_LIST";
export const RESPONSE_CART_CANCEL_ORDER = "RESPONSE_CART_CANCEL_ORDER";
export const REQUEST_CART_CANCEL_ORDER = "REQUEST_CART_CANCEL_ORDER";
export const RESPONSE_CART_ORDER_CHARGES = "RESPONSE_CART_ORDER_CHARGES";
export const REQUEST_CART_ORDER_CHARGES = "REQUEST_CART_ORDER_CHARGES";
export const RESPONSE_CART_ADD_MEMBERSHIP = "RESPONSE_CART_ADD_MEMBERSHIP";
export const REQUEST_CART_ADD_MEMBERSHIP = "REQUEST_CART_ADD_MEMBERSHIP";
export const RESPONSE_CLOSE_CART_ORDER = "RESPONSE_CLOSE_CART_ORDER";
export const REQUEST_CLOSE_CART_ORDER = "REQUEST_CLOSE_CART_ORDER";
export const RESPONSE_CART_GET_CLOSED_ORDER = "RESPONSE_CART_GET_CLOSED_ORDER";
export const REQUEST_CART_GET_CLOSED_ORDER = "REQUEST_CART_GET_CLOSED_ORDER";
export const CLEAN_REQUEST_CLOSE_ORDER = "CLEAN_REQUEST_CLOSE_ORDER";
export const CLEAN_CART_REDUCER_COMPLETE = "CLEAN_CART_REDUCER_COMPLETE";
export const CLEAN_CART_ORDER_TAX_CALCULATION =
  "CLEAN_CART_ORDER_TAX_CALCULATION";
export const CLEAN_CART_ORDER_INVENTORY_ASSIGN =
  "CLEAN_CART_ORDER_INVENTORY_ASSIGN";
export const CLEAN_CART_ORDER_ADD_MEMBERSHIP =
  "CLEAN_CART_ORDER_ADD_MEMBERSHIP";
export const CLEAN_CART_CANCEL_ORDER = "CLEAN_CART_CANCEL_ORDER";
export const CLEAN_NEW_MEMBERS_REDUCER = "CLEAN_NEW_MEMBERS_REDUCER";
export const CLEAN_RESPONSE_CART_ORDER_CHARGES_REDUCER =
  "CLEAN_RESPONSE_CART_ORDER_CHARGES_REDUCER";
export const REQUEST_CLOSE_CART_ORDER_GUEST = "REQUEST_CLOSE_CART_ORDER_GUEST";
export const RESPONSE_CLOSE_CART_ORDER_GUEST =
  "RESPONSE_CLOSE_CART_ORDER_GUEST";
export const RESPONSE_CART_GET_GIFT_LIST = "RESPONSE_CART_GET_GIFT_LIST";
export const REQUEST_CART_GET_GIFT_LIST = "REQUEST_CART_GET_GIFT_LIST";
export const CLEAN_CART_GET_GIFT_LIST = "CLEAN_CART_GET_GIFT_LIST";
export const RESPONSE_CART_ADD_CONFIGURABLE_GIFT =
  "RESPONSE_CART_ADD_CONFIGURABLE_GIFT";
export const REQUEST_CART_ADD_CONFIGURABLE_GIFT =
  "REQUEST_CART_ADD_CONFIGURABLE_GIFT";
export const CLEAN_CART_ADD_CONFIGURABLE_GIFT =
  "CLEAN_CART_ADD_CONFIGURABLE_GIFT";
export const REQUEST_CART_ORDER_CLOSE_INFO_CLI =
  "REQUEST_CART_ORDER_CLOSE_INFO_CLI";
export const CLEAN_CART_ORDER_CLOSE_INFO_CLI =
  "CLEAN_CART_ORDER_CLOSE_INFO_CLI";
export const CLEAN_CART_ORDER_OPERATION = "CLEAN_CART_ORDER_OPERATION";
export const CLEAN_CART_CLOSED_ORDER = "CLEAN_CART_CLOSED_ORDER";

//PAYMENT
export const REQUEST_STRIPE_PAYMENT = "REQUEST_STRIPE_PAYMENT";
export const RESPONSE_STRIPE_PAYMENT = "RESPONSE_STRIPE_PAYMENT";
export const REQUEST_STRIPE_PAYMENT2023 = "REQUEST_STRIPE_PAYMENT2023";
export const RESPONSE_STRIPE_PAYMENT2023 = "RESPONSE_STRIPE_PAYMENT2023";
export const CLEAN_PAYMENT_REDUCER = "CLEAN_PAYMENT_REDUCER";

///// REPAYMENT
export const REQUEST_GET_EXCHANGE_RATE = "REQUEST_GET_EXCHANGE_RATE";
export const RESPONSE_GET_EXCHANGE_RATE = "RESPONSE_GET_EXCHANGE_RATE";
export const REQUEST_GET_REASONS_RETURN = "REQUEST_GET_REASONS_RETURN";
export const RESPONSE_GET_REASONS_RETURN = "RESPONSE_GET_REASONS_RETURN";
export const REQUEST_CANCELLATION_COMPLETE = "REQUEST_CANCELLATION_COMPLETE";
export const RESPONSE_CANCELLATION_COMPLETE = "RESPONSE_CANCELLATION_COMPLETE";
export const REQUEST_FULL_REFUND = "REQUEST_FULL_REFUND";
export const RESPONSE_FULL_REFUND = "RESPONSE_FULL_REFUND";
export const REQUEST_PARTIAL_REFUND = "REQUEST_PARTIAL_REFUND";
export const RESPONSE_PARTIAL_REFUND = "RESPONSE_PARTIAL_REFUND";
export const CLEAN_REFOUND_REDUCER = "CLEAN_REFOUND_REDUCER";
export const CLEAN_REQUEST_FULL_REFUND_REDUCER =
  "CLEAN_REQUEST_FULL_REFUND_REDUCER";
export const CLEAN_REQUEST_PARTIAL_REFUND_REDUCER =
  "CLEAN_REQUEST_PARTIAL_REFUND_REDUCER";

//CUSTOM LABELS
export const REQUEST_CUSTOM_LABELS_CONFIGURATION =
  "REQUEST_CUSTOM_LABELS_CONFIGURATION";
export const RESPONSE_CUSTOM_LABELS_CONFIGURATION =
  "RESPONSE_CUSTOM_LABELS_CONFIGURATION";
export const REQUEST_VALIDATE_CUSTOM_LABELS = "REQUEST_VALIDATE_CUSTOM_LABELS";
export const RESPONSE_VALIDATE_CUSTOM_LABELS =
  "RESPONSE_VALIDATE_CUSTOM_LABELS";
export const REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION =
  "REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION";
export const RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION =
  "RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION";
export const CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION =
  "CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION";
//V2 CUSTOM LABELS
export const REQUEST_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER =
  "REQUEST_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER";
export const RESPONSE_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER =
  "RESPONSE_CUSTOM_LABEL_BUILD_IMAGES_ON_SERVER";
export const REQUEST_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL =
  "REQUEST_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL";
export const RESPONSE_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL =
  "RESPONSE_CUSTOM_LABELS_CONFIGURATION_BY_DETAIL";
export const REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL =
  "REQUEST_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL";
export const RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL =
  "RESPONSE_CUSTOM_LABEL_UPDATE_CONFIGURATION_BY_DETAIL";
export const REQUEST_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION =
  "REQUEST_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION";
export const RESPONSE_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION =
  "RESPONSE_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION";
export const CLEAN_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION =
  "CLEAN_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION";
export const CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION_BY_DETAIL =
  "CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION_BY_DETAIL";

///NEW MEMBERS V2
export const REQUEST_NEWMEMBER_REGISTER_NEW_PROSPECT =
  "REQUEST_NEWMEMBER_REGISTER_NEW_PROSPECT";
export const RESPONSE_NEWMEMBER_REGISTER_NEW_PROSPECT =
  "RESPONSE_NEWMEMBER_REGISTER_NEW_PROSPECT";
export const REQUEST_NEWMEMBER_GET_REGISTERED_NEW_PROSPECT =
  "REQUEST_NEWMEMBER_GET_REGISTERED_NEW_PROSPECT";
export const RESPONSE_NEWMEMBER__GET_REGISTERED_NEW_PROSPECT =
  "RESPONSE_NEWMEMBER__GET_REGISTERED_NEW_PROSPECT";
export const CLEAR_NEWMEMBER_REDUCER_REGISTRATION =
  "CLEAR_NEWMEMBER_REDUCER_REGISTRATION";
export const REQUEST_NEWMEMBER_CLOSE_ACTUAL_SESSION =
  "REQUEST_NEWMEMBER_CLOSE_ACTUAL_SESSION";

// LANDING REGISTRATION
export const REQUEST_LANDING_REGISTRATION_DATA = "REQUEST_LANDING_REGISTRATION_DATA";
export const RESPONSE_LANDING_REGISTRATION_DATA = "RESPONSE_LANDING_REGISTRATION_DATA";

export const REQUEST_LANDING_NEW_REGISTRATION_DATA = "REQUEST_LANDING_NEW_REGISTRATION_DATA";
export const RESPONSE_LANDING_NEW_REGISTRATION_DATA = "RESPONSE_LANDING_NEW_REGISTRATION_DATA";

export const CLEAN_LANDING_NEW_REGISTRATION_REDUCER = "CLEAN_LANDING_NEW_REGISTRATION_REDUCER";