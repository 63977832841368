import { combineReducers } from "redux";
import home from "./Home";
import navBar from "./Navbar";
import footer from "./Footer";
import products from "./Products";
import general from "./General";
import client from "./Client";
import twilio from "./Twilio";
import address from "./Address";
import email from "./Email";
import cart from "./Cart";
import payment from "./Payment";
import repayment from "./Repayment";
import referrer from "./Referrer";
import facebookAPI from "./Facebook";
import customLabels from "./CustomLabels";
import newMember from "./NewMember";
import {
  CLEAN_REDUCER_WEBPAGE_CONTACT_REGISTER,
  REQUEST_CLIENT_LOGOUT,
  CLEAN_REDUCER_CLIENT_REGISTER,
  CLEAN_REDUCER_CLIENT_UPDATE_INFORMATION,
  CLEAN_REDUCER_RECOVERY_CLIENT_PASSWORD,
  CLEAN_REDUCER_CELLPHONE_VALIDATION,
  CLEAN_REDUCER_EMAIL_VALIDATION,
  CLEAN_VALIDATE_IDTYPE_REDUCER,
  CLEAN_REDUCER_CLIENT_ADDRESS_VALIDATION,
  CLEAN_PAYMENT_REDUCER,
  CLEAN_REQUEST_CLOSE_ORDER,
  CLEAN_CART_REDUCER_COMPLETE,
  CLEAN_USER_INFORMATION_REDUCER,
  CLEAN_CLIENT_LOGIN_REDUCER,
  CLEAN_REGISTER_FROM_CHEKOUT_REDUCER,
  CLEAN_CART_ORDER_TAX_CALCULATION,
  CLEAN_CART_ORDER_INVENTORY_ASSIGN,
  CLEAN_CART_ORDER_ADD_MEMBERSHIP,
  CLEAN_REGISTER_FROM_CHEKOUT_FISCAL_ADDRESS_REDUCER,
  CLEAN_UPDATE_SHIPPING_ADDRESS,
  CLEAN_GET_DATA_SPONSOR_LIST,
  CLEAN_REFERRER_REDUCER,
  CLEAN_REFERRER_SENDED_CODE_LOG,
  CLEAN_CLIENT_UPLOAD_IMAGE_REDUCER,
  CLEAN_CLIENT_SUPPORT_QUESTION_REDUCER,
  CLEAN_SET_FAVORITES_REDUCER,
  CLEAN_REFOUND_REDUCER,
  CLEAN_GET_DATA_SPONSOR_AUTOMATIC,
  CLEAN_CLIENT_IMMEDIATE_GAINS_REDUCER,
  CLEAN_CLIENT_RETARGETING_PROGRAM,
  CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION,
  CLEAN_CART_CANCEL_ORDER,
  CLEAN_NEW_MEMBERS_REDUCER,
  CLEAN_REQUEST_FULL_REFUND_REDUCER,
  CLEAN_REQUEST_PARTIAL_REFUND_REDUCER,
  CLEAN_RESPONSE_CART_ORDER_CHARGES_REDUCER,
  CLEAN_TWILIO_CELLVALIDATION_REDUCER,
  CLEAN_TWILIO_CELL_CODEVALIDATION_REDUCER,
  CLEAN_CLIENT_GUEST_INFORMATION_EMAIL,
  CLEAN_CART_GET_GIFT_LIST,
  CLEAN_CART_ADD_CONFIGURABLE_GIFT,
  CLEAN_CART_ORDER_CLOSE_INFO_CLI,
  CLEAN_NEWSLETTER_SUSCRIBE_VALIDATION,
  CLEAN_CART_ORDER_OPERATION,
  CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION_BY_DETAIL,
  CLEAN_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION,
  CLEAN_GET_SETUP_KIT_COMPONENTS,
  CLEAN_ASSGIN_SPONSOR_REDUCER,
  CLEAR_NEWMEMBER_REDUCER_REGISTRATION,
  CLEAN_REDUCER_PASSWORD_RECOVERY,
  REQUEST_NEWMEMBER_CLOSE_ACTUAL_SESSION,
  CLEAN_USER_DATA_VALIDATION,
  CLEAN_CART_CLOSED_ORDER,
  CLEAN_LANDING_NEW_REGISTRATION_REDUCER
} from "./../Constants";

const appReducers = combineReducers({
  home,
  general,
  navBar,
  footer,
  products,
  client,
  twilio,
  address,
  email,
  cart,
  payment,
  repayment,
  facebookAPI,
  referrer,
  customLabels,
  newMember,
});

export default (state, action) => {
  // // if (action.type === "LOG_OUT") state = undefined;
  // // if (action.type === "CANCEL_ORDER") { state.storeActions = {}; state.checkOutActions = {}; state.generalActions.cartOrder = undefined; state.generalActions.showMiniCart = undefined; };
  // if (action.type === RESPONSE_USER_NEW_SESSION) { state.orderReducer = {}; state.paymentReducer={}; state.clientReducer={}; state.storeReducer={};state.checkOutReducer={};state.earningOrderReducer={};state.chatForClients={};state.referrerReducer={};state.qualificationReducer={} };
  // if (action.type == REQUEST_ORDER_REDUCER_CLEANUP) { state.orderReducer = {}; state.paymentReducer={}; state.storeReducer={};state.checkOutReducer={}; }
  // if( action.type === RESPONSE_LOGOUT) state = undefined;
  if (action.type === CLEAN_CART_CLOSED_ORDER) {
    delete state.cart.cartClosedOrder;
  }

  if (action.type === CLEAN_VALIDATE_IDTYPE_REDUCER) {
    delete state.client.validateIdentificationType;
  }
  if (action.type === CLEAN_REDUCER_CELLPHONE_VALIDATION) {
    delete state.twilio;
  }
  if (action.type === CLEAN_REDUCER_EMAIL_VALIDATION) {
    delete state.email;
  }
  if (action.type === CLEAN_REDUCER_CLIENT_ADDRESS_VALIDATION) {
    delete state.address;
  }
  if (action.type === CLEAN_REGISTER_FROM_CHEKOUT_FISCAL_ADDRESS_REDUCER) {
    delete state.client.insertFiscalAddress;
  }
  if (action.type === CLEAN_PAYMENT_REDUCER) {
    delete state.payment;
  }
  if (action.type === CLEAN_REQUEST_CLOSE_ORDER) {
    delete state.cart.closeCartOrder;
  }
  if (action.type === CLEAN_CART_REDUCER_COMPLETE) {
    delete state.cart;
  }
  if (action.type === CLEAN_USER_INFORMATION_REDUCER) {
    delete state.client.clientLogedinInformation;
  }
  if (action.type === CLEAN_CLIENT_LOGIN_REDUCER) {
    delete state.client;
  }

  if (action.type === CLEAN_REGISTER_FROM_CHEKOUT_REDUCER) {
    delete state?.client?.updateDataFromCheckoutResponse;
    delete state?.client?.errorUpdateDataFromCheckoutResponse;
    delete state?.client?.newRegistrationLoginData;
    delete state?.client?.errorRegistrationLoginDataResponse;
  }

  if (action.type === CLEAN_LANDING_NEW_REGISTRATION_REDUCER) {
    delete state?.client?.newRegistrationLandingData;
    delete state?.client?.errorRegistrationLandingDataResponse;
  }

  if (action.type === CLEAN_ASSGIN_SPONSOR_REDUCER) {
    delete state?.client?.assignResp;
  }
  if (action.type === CLEAN_REDUCER_WEBPAGE_CONTACT_REGISTER) {
    delete state.client.errorWebPageContactRegister;
    delete state.twilio.errorTwilioCellValidation;
    delete state.twilio.errorTwilioCellCodeValidation;
    delete state.twilio.code;
  }
  if (action.type === CLEAN_REDUCER_CLIENT_REGISTER) {
    state.twilio = {};
    state.address = {};
    state.email = {};
    delete state.client.errorClientRegistration;
    delete state.client.clientLogin;
  }
  if (action.type === CLEAN_REDUCER_RECOVERY_CLIENT_PASSWORD) {
    delete state.client.responsePasswordRecovery;
    delete state.client.userRecoveryCodeValidation;
    delete state.client.resetUserPassword;
  }
  if (action.type === CLEAN_REDUCER_CLIENT_UPDATE_INFORMATION) {
    state.twilio = {};
    state.address = {};
    state.email = {};
    delete state.client.errorClientUpdateInformation;
  }
  if (action.type === REQUEST_CLIENT_LOGOUT) {
    //BORRAR REDUCER ORDEN, PAGO
    localStorage.removeItem("ZUsaUInfo");
    delete state.client.errorClientLogin;
    delete state.client.clientLogin;
    delete state.client.errorGetClientAdministrationMenu;
    delete state.client.clientAdministrationMenu;
    delete state.client.clientLogedinInformationf;
    delete state.general.errorLoadFirstCharge;
    delete state.general.firstChargeModel;
    delete state.cart;
    delete state.client.errorLoadClientLogedInformation;
    delete state.repayment;
    state.twilio = {};
    state.address = {};
    state.email = {};
  }
  if (action.type === REQUEST_NEWMEMBER_CLOSE_ACTUAL_SESSION) {
    //BORRAR REDUCER ORDEN, PAGO
    localStorage.removeItem("ZUsaUInfo");
    delete state.client.errorClientLogin;
    delete state.client.clientLogin;
    delete state.client.errorGetClientAdministrationMenu;
    delete state.client.clientAdministrationMenu;
    delete state.client.clientLogedinInformationf;
    delete state.general.errorLoadFirstCharge;
    delete state.general.firstChargeModel;
    delete state.cart;
    delete state.client.errorLoadClientLogedInformation;
    delete state.repayment;
    state.twilio = {};
    state.address = {};
    state.email = {};
  }
  if (action.type === CLEAN_CART_ORDER_TAX_CALCULATION) {
    delete state?.cart?.errorCartTaxOrder;
    delete state?.cart?.cartTaxOrder;
  }
  if (action.type === CLEAN_CART_ORDER_INVENTORY_ASSIGN) {
    delete state?.cart?.errorCartInventoryAssign;
    delete state?.cart?.cartInventoryAssign;
  }
  if (action.type === CLEAN_CART_ORDER_ADD_MEMBERSHIP) {
    delete state?.cart?.cartAddMembership;
  }
  if (action.type === CLEAN_UPDATE_SHIPPING_ADDRESS) {
    delete state?.client?.updateShippingAddress;
    delete state?.client?.errorUpdateShippingAddress;
  }
  if (action.type === CLEAN_GET_DATA_SPONSOR_LIST) {
    delete state?.client?.assignedSponsors;
  }
  if (action.type === CLEAN_REFERRER_REDUCER) {
    delete state?.referrer?.responseReferrerEmailValidation;
    delete state?.referrer?.responseReferrerCellPhoneValidation;
    delete state?.referrer?.responseNewRegister;
  }
  if (action.type === CLEAN_REFERRER_SENDED_CODE_LOG) {
    delete state?.referrer?.responseReferrerCodeLog;
  }
  if (action.type === CLEAN_CLIENT_UPLOAD_IMAGE_REDUCER) {
    delete state?.client?.uploadProfileImageResponse;
  }
  if (action.type === CLEAN_CLIENT_SUPPORT_QUESTION_REDUCER) {
    delete state?.client?.registerSupportQuestionResponse;
  }
  if (action.type === CLEAN_SET_FAVORITES_REDUCER) {
    delete state?.products?.errorSetProductOnFavorites;
    delete state?.products?.isFavorite;
  }
  if (action.type === CLEAN_REFOUND_REDUCER) {
    state.repayment = {};
  }
  if (action.type === CLEAN_GET_DATA_SPONSOR_AUTOMATIC) {
    delete state?.client?.assignedSponsorsAutomatic;
  }
  if (action.type === CLEAN_CLIENT_IMMEDIATE_GAINS_REDUCER) {
    delete state?.client?.requestImmediateGainsResponse;
  }
  if (action.type === CLEAN_CLIENT_RETARGETING_PROGRAM) {
    delete state?.client?.errorSendEmailForRetargetingProgram;
    delete state?.client?.responseSendEmail;
  }
  if (action.type === CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION) {
    delete state?.customLabels?.responseUpdateCustomLabels;
  }
  if (action.type === CLEAN_CART_CANCEL_ORDER) {
    delete state?.cart?.cartCancelOrder;
  }
  if (action.type === CLEAN_NEW_MEMBERS_REDUCER) {
    delete state?.client;
    delete state?.products?.kitSetupComponents;
  }
  if (action.type === CLEAN_REQUEST_FULL_REFUND_REDUCER) {
    delete state?.repayment?.errorLoadrepayment;
    delete state?.repayment?.kitSetupComponents;
  }
  if (action.type === CLEAN_REQUEST_PARTIAL_REFUND_REDUCER) {
    delete state?.repayment?.errorLoadrepayment;
    delete state?.repayment?.partialRefund;
  }
  if (action.type === CLEAN_RESPONSE_CART_ORDER_CHARGES_REDUCER) {
    delete state?.cart?.cartOrderCharges;
    delete state?.cart?.errorCartOrderCharges;
  }
  if (action.type === CLEAN_TWILIO_CELLVALIDATION_REDUCER) {
    delete state?.twilio?.errorTwilioCellValidation;
    delete state?.twilio?.code;
  }
  if (action.type === CLEAN_TWILIO_CELL_CODEVALIDATION_REDUCER) {
    delete state?.twilio?.errorTwilioCellCodeValidation;
  }
  if (action.type === CLEAN_CLIENT_GUEST_INFORMATION_EMAIL) {
    delete state?.client?.errorEmailClientGuest;
    delete state?.client?.clientGuestInfo;
  }
  if (action.type === CLEAN_CART_GET_GIFT_LIST) {
    delete state?.cart?.cartGetGiftList;
  }
  if (action.type === CLEAN_CART_ADD_CONFIGURABLE_GIFT) {
    delete state?.cart?.cartAddConfigurableGift;
  }
  if (action.type === CLEAN_CART_ORDER_CLOSE_INFO_CLI) {
    delete state?.cart?.cartOrderCloseInfoCli;
    delete state?.cart?.errorCartOrderCloseInfoCli;
  }
  if (action.type === CLEAN_NEWSLETTER_SUSCRIBE_VALIDATION)
    delete state?.client?.newsLetterEvaluationResponse;
  if (action.type === CLEAN_CART_ORDER_OPERATION)
    delete state?.cart?.cartCurrentOrder;
  if (action.type === CLEAN_CUSTOM_LABEL_REDUCER_UPDATE_CONFIGURATION_BY_DETAIL)
    delete state?.customLabels?.responseUpdateCustomLabelsByDetail;
  if (action.type === CLEAN_CUSTOM_LABEL_CONFIGURATION_BY_PROMOTION)
    delete state?.customLabels?.responseCustomLabelsConfigurationByPromo;
  if (action.type === CLEAN_GET_SETUP_KIT_COMPONENTS)
    delete state?.products?.kitSetupComponents;
  delete state?.products?.errorKitSetupComponents;

  if (action.type === CLEAN_GET_SETUP_KIT_COMPONENTS) {
    delete state?.client?.responsePasswordRecovery;
  }
  if (action.type === CLEAR_NEWMEMBER_REDUCER_REGISTRATION) {
    delete state?.newMember.responseRegistration;
  }
  if (action.type === CLEAN_USER_DATA_VALIDATION) {
    delete state?.client?.userDataValidationResponse;
  }

  return appReducers(state, action);
};
